.myswiper {
    width: 80%;
    height: 100%;
}

.swiper_slide {
    text-align: center;
    font-size: 18px;
    background: #8b8b8b;
    height: 350px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 
}

.content_overlay{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.801);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column ;
    opacity: 0;
    transition: 0.6;
    
}

.content_overlay:hover{
  opacity: 1;
}

.content_overlay h1{
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 1px;
  border-bottom: 3px solid #ffbb34;
  
  
}

.content_overlay p {
  font-size: 15px;
  color: #fff;
  
}

.content_overlay > * {
  transform:translateY(100px);
  transition: transform 0.9s;
}

.content_overlay:hover > * {
  transform:translateY(0px);
}




.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Arrowbox {
    position: absolute;
    width: calc(100% - 10%);
    display: flex;
    padding: 18px 5%;
    justify-content: space-between;
    top: 50%;
    left: 0;
    height: 70px;
    z-index: 2;
    transform: translateY(-50%);
}

.button_prev_team {
    cursor: pointer;
}

.button_next_team {
    cursor: pointer;
    transform: scaleX(-1);
}

.button_prev_team:active {
    animation: moveLeft 1.5s infinite;
}

.button_next_team:active {
    animation: moveRight 1.5s infinite;
}

@keyframes moveLeft {
    0% {
      transform: translateX(-5px);
    }
  
    25% {
      transform: translateX(-20px);
    }
  
    50% {
      transform: translateX(-10px);
      /* Ajuste a distância da animação conforme necessário */
    }
  
    75% {
      transform: translateX(-20px);
    }
  
    100% {
      transform: translateX(-5px);
    }
  }
  
  @keyframes moveRight {
    0% {
      transform: translateX(5px) scaleX(-1);
    }
  
    25% {
      transform: translateX(20px) scaleX(-1);
    }
  
    50% {
      transform: translateX(10px) scaleX(-1);
      /* Ajuste a distância da animação conforme necessário */
    }
  
    75% {
      transform: translateX(20px) scaleX(-1);
    }
  
    100% {
      transform: translateX(5px) scaleX(-1);
    }
  }