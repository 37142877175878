.footer {
    width: 100%;
    display: flex;
}

.footerContainer {
    width: 100%;
    max-width: calc(1200px + 4%);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.boxnavigation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 2%;
    border-bottom: 2px solid black;
}

.boxnavigation img {
    width: 70px;
}

.boxnavigation a {
    font-weight: 600;
    text-decoration: none;
    color: rgb(48, 48, 48);
    margin: 0 15px;
    opacity: 0.9;
}

.boxnavigation a:hover {
    color: black;
    opacity: 1;
}

.copyright {
    padding: 30px 0;
    width: 100%;
    text-align: center;
}

.copyright img {
    padding: 0 10px;
}

@media screen and (max-width: 440px) {
    .boxnavigation a {
        display: block;
    }
}