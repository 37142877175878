.SectionBanner{
    display: flex;
    width: 100%;
    position: relative;
    font-family: "Open Sans", sans-serif;
    background-color: rgb(23, 23, 23);
    z-index: 1;
    font-family: "Aoboshi One", sans-serif;
    color: white;
}

.BannerContainer{
    width: 100%;
    max-width: calc(1200px + 4%);
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 4% 0;
    background-image: url("../../img/BackgroundFQ.png");
    background-size: cover;
    background-position: center;
}
.title{
    padding: 2%;
    margin: 50px 0;
}