/* Logo marca com icone gerando amor */
.navIcon {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #fff;
}

.navIcon h1 {
    padding-left: 30px;
    font-size: 2rem;
}

.navIcon h1 span {
    color: #ffbb33;
}

.img {
    width: 60px;
}

/* fim do css da Logomarca */

/* Responsividade */
@media screen and (max-width: 1023px) {
    .img {
        width: 40px;
    }

    .navIcon h1 {
        padding-left: 15px;
        font-size: 1.4rem;
    }
}