.SectionMap{
    display: flex;
    width: 100%;
    position: relative;
    font-family: "Open Sans", sans-serif;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    font-family: "Aoboshi One", sans-serif;
    color: rgb(0, 0, 0);
    
}

.MapContainer{
    width: 100%;
    max-width: calc(1200px + 4%);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 4% 0;
    align-items: center;
    -webkit-box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
    box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
}
.MapContainer h3{
    font-weight: 500;
    width: 60%;
    margin: 10px auto;    
}
.Mapa{
    width: 60%;
    height: 300px;
    margin: 10px auto;
    background-image: url("../../img/mapa.png");
    background-position: center;
    background-size: cover

}
@media screen and (max-width: 505px) {
    .Mapa{width: 90%;}
}