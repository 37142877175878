.SectionObjective {
    position: relative;
    z-index: 0;
    width: 100%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(23, 23, 23);
    font-family: "Aoboshi One", sans-serif;
}

.ContainerObjective {
    width: 100%;
    /* calc para calcular o pading */
    max-width: calc(1200px + 4%);
    margin: 0 auto;
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
    box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
    z-index: 0;
    text-align: center;
    background-image: url("../../img/back\ full\ gray\ icon.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.boxtext {
    width: 100%;
    margin: 80px 0;
}

.boxtext h1 {
    width: max-content;
    font-size: 50px;
    font-weight: 500;
    margin: 30px auto;
    position: relative;
}

.boxtext h1::after {
    content: "";
    position: absolute;
    bottom: -6px;
    /* Ajuste o valor conforme necessário para controlar a altura da borda */
    left: -5%;
    width: 110%;
    border-radius: 10px;
    height: 3px;
    /* Ajuste a espessura da borda conforme necessário */
    background-color: #ffbb34;
    /* Cor da borda */
    z-index: 0;
}

.boxSwiper{
 width: 100%;

}

.boxSwiper{
    position: relative;
}