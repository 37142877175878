.w50 {
  width: 50%;
}
.w100 {
  width: 100%;
}
span {
  color: #ffbb34;
}
@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.onload {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background: linear-gradient(90deg, #313131 25%, #2e2e2e 50%, #313131 75%);
  background-size: 200%;
  animation: loading 1.5s infinite;
  border-radius: 0 200px 0 0;
}
.imagequemsomos .onload{
    border-radius: 0 0 0 200px;
}
/* section descriptiion */

.section_description {
  position: relative;
  z-index: 0;
  width: 100%;
  color: white;
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(23, 23, 23);
  font-family: "Aoboshi One", sans-serif;
}

.container_description {
  width: 100%;
  /* calc para calcular o pading que deixa a container banner maior que esse container */
  max-width: calc(1200px + 4%);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -webkit-box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
  box-shadow: 0px -3px 29px 4px rgba(0, 0, 0, 0.36);
  z-index: 0;
}

/* image description */
.container_description div img {
  width: 100%;
}
.imagequemsomos img {
  border-radius: 0 0 0 200px;
}
.imagetransformamos{
    display: flex;
}
.imagetransformamos img {
  border-radius: 0 200px 0 0;
  position: relative;
  bottom: -5px;
}

/* texto description */
.container_description h1 {
  font-weight: 500;
  font-size: 30px;
  padding: 3px 0;
  margin: 0px auto 20px 40px;
  width: max-content;
  border-bottom: 2px solid rgb(255, 238, 0);
}

.boxtext_description p {
  margin: 0 40px 10px 40px;
  font-size: 1rem;
}

/* row reverse container responsive */
.containertranformamos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1080px) {
  /* tags gerais */
  .w50 {
    width: 100%;
  }

  .w100 {
    width: 100%;
  }
  /* section description */
  /* row reverse container responsive */
  .containertranformamos {
    flex-direction: column-reverse;
  }

  /* image description */
  .container_description div img {
    border-radius: 8px;
  }

  .imagetransformamos,
  .imagequemsomos {
    width: 70%;
    margin: 40px auto;
  }

  /* text description responsive */
  .container_description {
    text-align: center;
  }

  .container_description h1 {
    margin: 40px auto;
  }

  .container_description p {
    margin: 0 4%;
  }
  .boxvideo_team {
    width: 80%;
  }
  .onload{
    border-radius: 5px;
  }
  .imagequemsomos .onload{
    border-radius: 5px;
}
}

@media screen and (max-width: 400px) {
  .imagetransformamos,
  .imagequemsomos {
    width: 90%;
  }

  .container_description h1 {
    font-size: 25px;
  }

  .container_description p {
    font-size: 14px;
  }
}
