

.sectionLayout{
    display: flex;
    height: 500px;
    background: linear-gradient(to bottom, #1E1E1E 50%, #FFFFFF 50%);
    font-size: 65%;
}
.container{
    width: 100%;
    max-width: calc(1200px + 4%);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0 2%;
    align-items: center; 
    align-self: center;
    justify-content: space-between;
}


.cardsLayout{
    width: 200px;
    height: 280px;
    border-radius: 20px;
    border: 1px solid #000;
    background-color: #FFFFFF;
    align-items:center;
    text-align: center;
}

.projectInfo img{
    width: 80px;
    height: 82.715px;
    margin: 5px;

}


.projectInfo h4{
    color: black;
    font-family: Aoboshi One;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.projectInfo p{
    color: black;
    font-family: Aoboshi One;
    font-weight: 400;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;

}

.projectInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.btnsCards{
    display: flex;
    width: 119px;
    height: 25px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #000;
    background: #FCA311;
    margin: 7px;
    text-decoration: none;
}

.btnsCards a{
    color: #000000;
    text-decoration: none;
    font-family: Aoboshi One;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.cardDif{
    display: flex;
    width: 540px;
    height: 272px;
}

.cardStyle{
    text-align: center;
}

.cardStyle img{
    align-items: center;
}

.cardStyle h4{
    text-align: center;
    font-family: Aoboshi One;
    font-size: 15px;
    font-weight: bolder;
    line-height: normal;
    margin-top: 5px;
}

.cardStyle p{
    text-align: center;
    font-family: Aoboshi One;
    font-size: 15px;
    font-weight: bolder;
    text-align: center;
}

.layout{
    margin-top: 15px;
}
.conteudo{
    display: flex;
    align-items:center;
    justify-content: space-around;
} 

.infosCard{
    border: 1px solid #000;
    background: #FFE500;
    color:#000000;
    
}

.cardImage{
    width: 50%;
}

@media(max-width:1200px){
    .sectionLayout{
        height: 100%;
    }
    .container{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background:#1E1E1E;
        padding: 20px 2%;
    }
    .cardImage{
        width: 50%;
    }
    .cardsLayout{
        margin: 20px 0;
    }
    .cardDif{
        max-width: 800px;
        width: auto;
    }
}

@media(max-width:560px){

    .cardDif{
        height: auto;
        justify-content: center;
        flex-direction: column-reverse;
        max-width: 300px;
    }

    .cardImage{
        height: 273px;
        width: 100%;
    }
    .Image{
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .infosCard{
        border: 1px solid #000;
        background: #FFE500;
        color:#000000;
    }
    
}