.w50{ width: 50%;}

.cards_projects {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.cards_projects h6{
    color: coral;
}
.description_projects{
    min-height: 250px;
    padding: 10px 0;
}
.description_projects h1 {
    color: white;
    font-size: 30px;
    padding-left: 10px;
}

.description_projects p {
    padding: 0 10px;
    color: rgb(226, 226, 226);
    font-size: 1rem;
}
.description_projects a{
    color: black;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    margin: 0 10px;
    padding: 5px 20px;
}
.description_projects a:hover{
    color: brown;
}
.img_projects {
    min-height: 250px;
    background-size: cover;
    background-position: center;    
}
@media screen and (max-width: 700px) {
    .w50{ width: 100%;}
    .cards_projects{ 
        flex-direction: column-reverse; 
        padding: 0 5%;
    }
}