.navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    background-color: #222;
    position: relative;
    z-index: 99;
}

/* Mobile Hamburguer */
.mobilehamburguer {
    display: flex;
    list-style: none;
    align-items: center;
}
.mobileIcon{
    display:flex;
    flex-direction:column;
    width: 30px;
    cursor:pointer;
    align-items: flex-end;
}
   
.mobileIcon span{
    background: #ffbb33;
    border-radius:10px;
    height: 4px;
    margin: 3px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
   
   
.mobileIcon span:nth-of-type(1){
    width:50%;   
}
   
.mobileIcon span:nth-of-type(2){
    width:100%;
}
   
   
.mobileIcon span:nth-of-type(3){
    width:75%; 
}
   
   
.mobileIcon input[type="checkbox"]{
    display:none;
}


.mobileIcon input[type="checkbox"]:checked ~ span:nth-of-type(1){
    transform-origin:bottom;
    transform:rotatez(45deg) translate(0px, 10px);
    opacity: 0;
}


.mobileIcon input[type="checkbox"]:checked ~ span:nth-of-type(2){   
    transform-origin:top;
    transform:rotatez(-45deg);
    width: 100%;
}


.mobileIcon input[type="checkbox"]:checked ~ span:nth-of-type(3){  
    transform-origin:bottom;
    width:100%;
    transform: translate( 0px,-11px) rotatez(45deg);
}
/* fim icon menu */