section{
    background-color: #FFFFFF;
}

.BackgroundImg{
    background-image: URL("../../img/Background_Security.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
}

@media screen and (max-width: 1080px) {
    .BackgroundImg {
        height: 160px;
    }
}

/* Adicionar regras de mídia para 780px */
@media screen and (max-width: 780px) {
    .BackgroundImg {
        height: 120px; 
    }
}
