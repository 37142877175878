.w50 {
  width: 50%;
}
@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.onload {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-top-left-radius: 30%; /* Canto superior esquerdo */
  border-bottom-right-radius: 30%; /* Canto inferior direito */
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200%;
  animation: loading 1.5s infinite;
}

.section_banner {
  display: flex;
  width: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background-color: white;
  z-index: 1;
}

.container_banner {
  width: 100%;
  max-width: calc(1200px + 4%);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  background-image: url("../../img/Gerando\ amor\ 1.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 4% 0;
  padding-bottom: 120px;
}

.seta {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* div de texto */
.boxtext h1,
.boxtext h3,
.boxtext h4 {
  padding: 0 0 0 4%;
}

.boxtext {
  position: relative;
  z-index: 1;
  align-self: center;
}

.boxtext h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.boxtext h3 {
  font-size: 1.5rem;
}

.boxtext h4 {
  color: #ffbb34;
  margin: 60px 0;
  font-size: 1.5rem;
}

.boxtext button {
  background-color: #ffbb34;
  border: none;
  font-weight: 600;
  color: white;
  padding: 20px 60px;
  border-radius: 5px;
  margin: 0 4%;
  -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.51);
  cursor: pointer;
}

/* css Imagen */
.boximage {
  position: relative;
  display: flex;
}

.caixa_item {
  display: flex;
  position: absolute;
  left: -25px;
  bottom: 20px;
  width: 50px;
  height: 70%;
  background: linear-gradient(180deg, #fca311 0%, #d78500 52.63%, #462c00 100%);
}

.boximage img {
  width: 100%;
  border-top-left-radius: 30%; /* Canto superior esquerdo */
  border-bottom-right-radius: 30%; /* Canto inferior direito */
  box-shadow: 0 40px 8px rgba(0, 0, 0, 0.1);
}

/* fim banner section */

@media screen and (max-width: 1080px) {
  /* tags gerais */
  .w50 {
    width: 100%;
  }

  /* Section banner */
  .container_banner {
    background: none;
  }

  .container_banner::before {
    content: "";
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    height: 90%;
    background: url("../../img/Gerando\ amor\ 1.png");
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    /* Ajuste a opacidade conforme necessário */
    z-index: -1;
  }

  /* banner imagem */
  .boximage {
    max-width: 600px;
    padding: 0 50px;
    margin: 0 auto;
    margin-top: 80px;
  }

  .caixa_item {
    left: 25px;
  }

  /* button banner */
  .boxtext .button button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 500px){
  .onload{
    min-height: 260px;
  }
}