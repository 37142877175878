.Background{
    background-image: URL("../../img/background_Associado.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 361px;
}



@media(max-width:1200px){
    .BackgroundImg{
        width: 100%;
        height: 200px;
    }

}