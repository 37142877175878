section{
    background-color: #FFFFFF;
}

.BackgroundImg{
    background-image: URL("../../img/background_voluntario.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: width 100;
    width: 100%;
    height: 361px;
}



@media(max-width: 1200px){
    .BackgroundImg{
        width: 100%;
        height: 200px;
    }
    

}