.modal{
    background-color: #222;
    position: absolute;
    top: 100%;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.modal ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-left: 20px;
    margin-bottom: 10px;
}

.modal li {
   padding-bottom: 10px;
}

.modal a{
    text-decoration: none;
    color: #ffbb33;
    font-size: 18px;
}

button{
    margin-left: 20px;
    background-color: #ffbb33;
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}