.w50 {
    width: 50%;
}

.section_team {
    display: flex;
    width: 100%;
    position: relative;
    font-family: "Aoboshi One", sans-serif;
    background-color: white;
    z-index: 1;
}

.container_team {
    width: 100%;
    /* calc para calcular o pading que deixa a container banner maior que esse container */
    max-width: calc(1200px + 4%);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

.boxtext_team {
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

.boxtext_team h1 {
    font-size: 30px;
    margin: 40px auto;
    width: max-content;
    position: relative;
}

.boxtext_team h1::before {
    content: "";
    position: absolute;
    bottom: -6px;
    /* Ajuste o valor conforme necessário para controlar a altura da borda */
    left: -5%;
    width: 110%;
    border-radius: 10px;
    height: 3px;
    /* Ajuste a espessura da borda conforme necessário */
    background-color: #ffbb34;
    /* Cor da borda */
    z-index: 0;
}

.boxvideo_team {
    width: 50%;
    height: 300px;
    margin: 50px auto;
    border-radius: 10px;
    position: relative;
}
.boxvideo_team::before{
    content: "";
    position: absolute;
    bottom: -20px;
    /* Ajuste o valor conforme necessário para controlar a altura da borda */
    left: -5%;
    width: 110%;
    border-radius: 10px;
    height: 3px;
    /* Ajuste a espessura da borda conforme necessário */
    background-color: #000000;
    /* Cor da borda */
    z-index: 0;
}
.boxvideo_team iframe{
    width: 100%;
}
.boxtext_team p{ 
    margin: 0 auto;
    font-size: 18px;
}
/* slider backcolor */
.boxslider_team{
    position: relative;
    width: 100%;
    margin: 100px 0;
}
.backcolor_team{
    position: absolute;
    width: calc(100% - 10%);
    display: flex;
    padding: 18px 5%;
    justify-content: space-between;
    top: 50%;
    left: 0;
    height: 70px;
    background-color: rgb(23, 23, 23);
    transform: translateY(-50%);
    -webkit-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.3);

}

.slider_team{
    position: relative;
    width: 100%;
    height: 250px;
}
.button_prev_team{
    position: relative;
    z-index: 99;
}
.button_next_team{
    position: relative;
    z-index: 99;
}

/* responsivo */
@media screen and (max-width: 1080px) {

    /* tags gerais */
    .w50 {
        width: 100%;
    }
  
    .boxvideo_team {
        width: 80%;
    }
}

@media screen and (max-width: 400px) {


    .boxvideo_team {
       height: 170px;
    }
}