.section_projects{
    padding-bottom: 30px;
}
.container_projects {
    max-width: calc(800px + 4%);
    margin: 0 auto;
    padding: 0 2%; 
}

.tittle_projects {   
    margin: 0 auto;
    width: 85%;
}

.tittle_projects h1 {  
    font-weight: 500;
    font-size: 35px;
    width: max-content;
    position: relative;
}
.tittle_projects h1::after{
    content: "";
    position: absolute;
    bottom: -3px;
    left: -5%;
    background-color: #FCA311;
    height: 3px;
    width: 110%;
    border-radius: 10px;
}


@media screen and (max-width: 700px) {
    .section_projects{
        background-color: rgb(23, 23, 23);
    }

    .tittle_projects {
        display: flex;
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        height: 100px; /* Defina a altura desejada para a div */
        color: white;
    }
   
}

