body {
    font-family: "Aoboshi One";
}



.Section_AssociadoText{
    display: flex;
    align-items: center; 
    justify-content: center;
    max-width: 100%;
  
}

.Container_AssociadoText{

    align-items: center;
    max-width: calc(1200px + 4%);

}

.List{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.Div_h1{
    display: flex;
    
}

.Div_h1 h1{
    font-size: 25px;
    margin-bottom: 1px;
    border-bottom: 4px solid #ffbb34;
}



.List li{
    margin: 15px;
    margin-top: 20px;
    font-size: 15px;

}




.btnsDiv{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    

}

.btnsDiv a{
    border-radius: 6px;
    border: 1px solid #000;
    background: #FCA311;
    padding: 10px 30px;
    text-decoration: none;
    color: #000000;
    
}

.stylea a{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    display: flex;
}

@media screen and (max-width: 400px){

    .Div_h1 h1{
        font-size: 20px;
        text-align: center;
    }

}